import React, { useState } from 'react';
import "./orderform.css";

const OrderForm = () => {
 
 

  return (
    <div className="footer">
    <div className="footer-content">
      <p>Phone: 123-456-7890</p>
      <p>Email: info@example.com</p>
      <p>Address: 123 Street, City, Country</p>
    </div>
  </div>
  );
};

export default OrderForm;
